import { PlaceholderFilters } from 'components/PlaceholderFilters/PlaceholderFilters'
import type { FilterAction, FilterState } from 'hooks/useFilterReducer'
import React, { useMemo, useState, type Dispatch } from 'react'
import ReactSlider from 'react-slider'
import type { Category, Course } from 'types/course'

const getAllCategories = (courses: Course[]) => {
  const categoriesList = new Set<Category>()
  courses.forEach((course) => {
    course.categories.forEach((category) => {
      categoriesList.add(category)
    })
  })

  return Array.from(categoriesList)
}

type CourseFiltersProps = {
  state: FilterState
  dispatch: Dispatch<FilterAction>
  courses: Course[]
  loading?: boolean
}

export const CoursesFilters = ({
  state,
  dispatch,
  courses,
  loading = false,
}: CourseFiltersProps) => {
  if (loading) {
    return <PlaceholderFilters />
  }

  // Get all categories from the courses
  const categories = useMemo(() => getAllCategories(courses), [courses])

  const [isVisible, setIsVisible] = useState(false)

  const formats = [
    'Free',
    'Paid',
    'Samplers',
    'Short courses',
    'Micro credentials',
  ]

  return (
    <div>
      <div className='flex justify-between gap-8'>
        <div className='flex flex-wrap gap-8'>
          {categories.length > 0 &&
            categories.map((category) => (
              <button
                className={`relative text-comet text-lg ${
                  state.selectedCategories.includes(String(category.id))
                    ? 'before:w-full'
                    : 'before:w-0'
                } before:h-1 before:absolute before:bg-purple-heart before:-bottom-4 before:rounded-t-full before:left-0 before:transform before:transition-all before:duration-300 before:ease-in-out`}
                key={category.id}
                onClick={() =>
                  dispatch({
                    type: 'TOGGLE_CATEGORY',
                    payload: String(category.id),
                  })
                }
              >
                {category.title}
              </button>
            ))}
        </div>
        <button
          onClick={() => setIsVisible(!isVisible)}
          className='py-2 px-5 border flex self-start items-center gap-2 rounded-full'
        >
          <svg
            width='20'
            height='19'
            viewBox='0 0 20 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.16634 0.0820312C6.98095 0.0820312 5.9708 0.854479 5.58822 1.91536H0.833008V3.7487H5.58822C5.9708 4.80958 6.98095 5.58203 8.16634 5.58203C9.67427 5.58203 10.9163 4.33996 10.9163 2.83203C10.9163 1.32411 9.67427 0.0820312 8.16634 0.0820312ZM8.16634 1.91536C8.68346 1.91536 9.08301 2.31491 9.08301 2.83203C9.08301 3.34915 8.68346 3.7487 8.16634 3.7487C7.64922 3.7487 7.24967 3.34915 7.24967 2.83203C7.24967 2.31491 7.64922 1.91536 8.16634 1.91536ZM12.7497 1.91536V3.7487H19.1663V1.91536H12.7497ZM12.7497 6.4987C11.5643 6.4987 10.5541 7.27115 10.1715 8.33203H0.833008V10.1654H10.1715C10.5541 11.2263 11.5643 11.9987 12.7497 11.9987C14.2576 11.9987 15.4997 10.7566 15.4997 9.2487C15.4997 7.74077 14.2576 6.4987 12.7497 6.4987ZM12.7497 8.33203C13.2668 8.33203 13.6663 8.73158 13.6663 9.2487C13.6663 9.76582 13.2668 10.1654 12.7497 10.1654C12.2326 10.1654 11.833 9.76582 11.833 9.2487C11.833 8.73158 12.2326 8.33203 12.7497 8.33203ZM17.333 8.33203V10.1654H19.1663V8.33203H17.333ZM5.41634 12.9154C4.23095 12.9154 3.22079 13.6878 2.83822 14.7487H0.833008V16.582H2.83822C3.22079 17.6429 4.23095 18.4154 5.41634 18.4154C6.92427 18.4154 8.16634 17.1733 8.16634 15.6654C8.16634 14.1574 6.92427 12.9154 5.41634 12.9154ZM5.41634 14.7487C5.93346 14.7487 6.33301 15.1482 6.33301 15.6654C6.33301 16.1825 5.93346 16.582 5.41634 16.582C4.89922 16.582 4.49967 16.1825 4.49967 15.6654C4.49967 15.1482 4.89922 14.7487 5.41634 14.7487ZM9.99967 14.7487V16.582H19.1663V14.7487H9.99967Z'
              fill='currentColor'
            />
          </svg>
          Filters
        </button>
      </div>

      {/* Sidebar Filters */}
      <div
        className={`fixed overflow-y-auto bg-white right-0 top-0 h-screen z-50 p-6 shadow-lg transition-all ease-in-out duration-300 ${isVisible ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <div className='flex items-center mb-6'>
          <h1 className='font-heading text-3xl flex-1 font-medium'>Filters</h1>
          <button onClick={() => setIsVisible(!isVisible)}>
            <svg
              className='text-purple-heart'
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.69044 0.696029C1.46906 0.662998 1.23571 0.714445 1.04102 0.858382C0.653397 1.14626 0.570286 1.69522 0.858161 2.08372C3.02073 5.00483 5.34064 7.70937 7.75049 10.1535C5.56903 12.2516 3.32642 14.1331 1.05982 15.7214C0.663446 15.9988 0.568823 16.5444 0.846198 16.9399C1.01682 17.1832 1.28835 17.3125 1.56397 17.3125C1.73722 17.3125 1.91074 17.2611 2.06299 17.1553C4.41481 15.5072 6.74108 13.5563 8.99976 11.3806C11.2583 13.556 13.5832 15.5056 15.9348 17.1535C16.0879 17.2603 16.2623 17.3125 16.4356 17.3125C16.7112 17.3125 16.9845 17.1823 17.1533 16.9382C17.4307 16.5418 17.3344 15.9971 16.938 15.7197C14.6714 14.1314 12.4288 12.2516 10.2473 10.1535C12.6572 7.70923 14.977 5.00448 17.1397 2.08372C17.4275 1.69522 17.347 1.14797 16.9585 0.860091C16.57 0.572216 16.021 0.653619 15.7332 1.04124C13.6178 3.89826 11.3518 6.54268 8.99976 8.92821C6.64738 6.54245 4.38028 3.89861 2.26465 1.04124C2.12072 0.847431 1.91181 0.72906 1.69044 0.696029Z'
                fill='currentColor'
              />
            </svg>
          </button>
        </div>

        {/* Full Text Search */}

        <div className='relative flex border border-mischka rounded-full p-1 mb-6'>
          <input
            id='search'
            type='text'
            placeholder='Search by keyword'
            value={state.search}
            onChange={(e) =>
              dispatch({ type: 'UPDATE_SEARCH', payload: e.target.value })
            }
            className='w-full border-none rounded-full focus:ring-2 focus:ring-purple-heart pr-10 placeholder-santas-gray'
          />
          <svg
            className='absolute right-4 top-[0.9rem] text-gray-50'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.34717 0.244141C3.41895 0.244141 0.217774 3.44385 0.217773 7.37207C0.217774 11.3003 3.41895 14.5015 7.34717 14.5015C8.16331 14.5015 8.94508 14.3572 9.67627 14.103C9.99519 15.1653 10.5193 16.2307 11.1396 17.1528C11.9329 18.3319 12.7958 19.3126 13.9185 19.5962C17.5765 20.5194 20.6408 17.1244 19.5537 13.8892C19.1924 12.8137 18.2339 11.9433 17.0884 11.1748C16.1679 10.5573 15.1131 10.0454 14.0679 9.729C14.3283 8.98985 14.4766 8.19866 14.4766 7.37207C14.4766 3.44385 11.2754 0.244141 7.34717 0.244141ZM7.34717 1.74414C10.4647 1.74414 12.9766 4.25451 12.9766 7.37207C12.9766 9.51842 11.7855 11.3775 10.0264 12.3276C9.9115 12.3527 9.80412 12.4044 9.71289 12.4785C8.99344 12.8112 8.19431 13.0015 7.34717 13.0015C4.22961 13.0015 1.71777 10.4896 1.71777 7.37207C1.71777 4.25451 4.22961 1.74414 7.34717 1.74414ZM13.4072 11.1045C14.3397 11.3579 15.384 11.8382 16.2534 12.4214C17.2478 13.0884 17.9819 13.9174 18.1328 14.3667C18.8532 16.5105 16.9386 18.8114 14.2847 18.1416C13.9486 18.0557 13.0734 17.3385 12.3848 16.3149C11.7994 15.4449 11.3112 14.3891 11.0562 13.4468C12.0118 12.8606 12.8174 12.0577 13.4072 11.1045Z'
              fill='currentColor'
            />
          </svg>
        </div>

        {/* Categories */}
        <div className='mb-4 pb-4 border-b border-mischka'>
          <h2 className='font-heading font-medium text-xl mb-2 text-cinder'>
            Categories
          </h2>
          {categories.length > 0 &&
            categories.map((category) => (
              <div key={category.id} className='flex items-center py-2'>
                <input
                  id={String(category.id)}
                  type='checkbox'
                  className='cursor-pointer mr-3 w-4 h-4 border border-comet rounded-[4px] hover:bg-mischka hover:checked:bg-purple-heart focus:hover:checked:bg-purple-heart focus:hover:checked:ring-purple-heart checked:bg-purple-heart focus:ring-purple-heart focus:checked:bg-purple-heart'
                  checked={state.selectedCategories.includes(
                    String(category.id),
                  )}
                  onChange={() =>
                    dispatch({
                      type: 'TOGGLE_CATEGORY',
                      payload: String(category.id),
                    })
                  }
                />
                <label
                  htmlFor={String(category.id)}
                  className='cursor-pointer text-comet text-sm font-medium'
                >
                  {category.title}
                </label>
              </div>
            ))}
        </div>

        {/* Formats */}
        <div className='mb-4 pb-4 border-b border-mischka'>
          <h2 className='font-heading font-medium text-xl mb-2 text-cinder'>
            Formats
          </h2>
          {formats.map((format) => (
            <div key={format} className='flex items-center py-2'>
              <input
                id={format}
                type='checkbox'
                className='cursor-pointer mr-3 w-4 h-4 border border-comet rounded-[4px] hover:bg-mischka hover:checked:bg-purple-heart focus:hover:checked:bg-purple-heart focus:hover:checked:ring-purple-heart checked:bg-purple-heart focus:ring-purple-heart focus:checked:bg-purple-heart'
                checked={state.selectedFormats.includes(format)}
                onChange={() =>
                  dispatch({ type: 'TOGGLE_FORMAT', payload: format })
                }
              />
              <label
                htmlFor={format}
                className='cursor-pointer text-comet text-sm font-medium'
              >
                {format}
              </label>
            </div>
          ))}
        </div>

        {/* Duration Slider */}
        <div className='mb-4 pb-4 border-mischka'>
          <h2 className='font-heading font-medium text-xl mb-2 text-cinder'>
            Duration
          </h2>

          <ReactSlider
            className='my-6 ml-7 mr-7 mt-16'
            thumbClassName='bg-white -translate-y-1.5 border-2 border-purple-heart h-4 w-4 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 cursor-pointer'
            trackClassName='bg-gray-50 h-1 rounded-lg'
            min={state.durationMinMax[0]} // min range value
            max={state.durationMinMax[1]} // max range value
            value={state.durationRange} // selected range
            onChange={(value) => {
              // Ensure value is an array with two numbers (min and max range)
              if (Array.isArray(value)) {
                dispatch({ type: 'UPDATE_DURATION', payload: value })
              }
            }}
            renderThumb={(props, state) => (
              <div {...props}>
                <div className='absolute bg-purple-heart -translate-x-1/2 ml-2 text-white rounded-md whitespace-nowrap text-xs font-medium -left-0.5 -top-9 px-3 py-1 after:w-2 after:h-2 after:rotate-45 after:bg-purple-heart after:absolute after:left-1/2 after:-bottom-1/2 after:z-[-1] after:-translate-x-1/2 after:-translate-y-full'>
                  {`${state.valueNow} ${state.valueNow === 1 ? 'week' : 'weeks'}`}
                </div>
              </div>
            )}
          />
        </div>

        {/* Buttons */}
        <div className='flex justify-center mt-6'>
          <button
            onClick={() => dispatch({ type: 'CLEAR_FILTERS' })}
            className='border border-tuna px-5 py-2 rounded-full'
          >
            Clear all filters
          </button>
        </div>
      </div>
    </div>
  )
}
