import { useQuery } from '@apollo/client'
import { useLocation } from 'context/Location/LocationProvider'
import { GET_USER } from 'gql/users'
import { getCoursePrice } from 'lib/currency'
import { formatPrice } from 'lib/utils'
import { useSession } from 'next-auth/client'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { Course } from 'types'
import { User } from 'types/user'
import ClockIcon from '../../svg/clock.svg'
import DocumentIcon from '../../svg/document.svg'

// Common card dimensions in the site
export const CardDimensions = {
  Width: 600,
  Height: 425,
}

type CourseCardProps = {
  course: Course
}

const CourseCard = ({ course }: CourseCardProps) => {
  const { t } = useTranslation()
  const {
    title,
    timeframeWeeks,
    hoursPerWeek,
    isComingSoon,
    imageUrl,
    isFree,
    slug,
    courseProvider: provider,
    categories,
  } = course

  const [session] = useSession()

  const { country } = useLocation() // Use LocationContext to access country code

  // Load current profile
  const { data: userData } = useQuery<{ me: User }>(GET_USER, {
    skip: !session?.user,
  })
  const user = session?.user && userData?.me

  // Calculate the course price based on the stored country code (either from cookies or user)
  const coursePrice = getCoursePrice(course, user, country)

  const linkUrl = `/courses/${slug}`

  return (
    <div
      className={`flex flex-col rounded-lg overflow-hidden h-full border-mischka ${course.isRecommended ? 'bg-dew shadow-hilight' : 'border-1 bg-white'} `}
    >
      <Link href={linkUrl}>
        <a>
          <div
            className='relative flex-shrink-0 text-0'
            style={{
              aspectRatio: `${CardDimensions.Width} / ${CardDimensions.Height}`,
            }}
          >
            {(isComingSoon || course.isRecommended) && (
              <div className='absolute top-4 right-4 flex gap-2'>
                {course.isRecommended && (
                  <div className='bg-turquoise items-center px-4 py-2 rounded-full z-10'>
                    <div className='leading-5 text-xs font-bold'>
                      {t('Featured')}
                    </div>
                  </div>
                )}
                {isComingSoon && (
                  <div className='bg-white items-center px-4 py-2 rounded-full z-10'>
                    <div className='leading-5 text-xs font-medium'>
                      {t('Coming Soon')}
                    </div>
                  </div>
                )}
              </div>
            )}
            {imageUrl && (
              <Image
                className='w-full object-cover'
                src={imageUrl}
                alt={title}
                width={CardDimensions.Width}
                height={CardDimensions.Height}
                priority
              />
            )}
          </div>
        </a>
      </Link>
      <div className='flex-1 p-6 flex flex-col justify-between'>
        <div className='flex flex-col gap-4 flex-1'>
          {categories.length > 0 && (
            <div className='flex flex-wrap gap-2'>
              {categories.map((category) => (
                <div
                  key={category.id}
                  className='px-3 py-[0.125rem] text-sm border font-medium rounded-md'
                >
                  {category.title}
                </div>
              ))}
            </div>
          )}
          <Link href={linkUrl}>
            <a className='block'>
              <p className='text-2xl font-medium font-heading'>{title}</p>
            </a>
          </Link>
          {provider && (
            <p className='flex-auto text-sm font-medium'>{provider.title}</p>
          )}
          <div className='flex'>
            {timeframeWeeks && (
              <div className='flex px-0.5'>
                <ClockIcon className='text-purple-heart' />

                <div className='text-sm font-medium px-2 pt-0.5 truncate'>
                  {timeframeWeeks} {t('weeks')}
                </div>
              </div>
            )}
            {hoursPerWeek && (
              <div className='flex px-0.5'>
                <DocumentIcon className='text-purple-heart' />

                <div className='text-sm font-medium px-2 pt-0.5 truncate'>
                  {hoursPerWeek} hours/week
                </div>
              </div>
            )}
          </div>
          <div className='flex justify-between items-center'>
            <div className='text-xl font-semibold'>
              {isFree
                ? 'Free'
                : formatPrice(coursePrice[0], coursePrice[1], true)}
            </div>
            <Link href={linkUrl} passHref>
              <a>
                <svg
                  className='text-tuna'
                  width='36'
                  height='36'
                  viewBox='0 0 36 36'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M17.9995 0.52002C8.36 0.52002 0.519531 8.36049 0.519531 18C0.519531 27.6396 8.36 35.48 17.9995 35.48C27.6391 35.48 35.4795 27.6396 35.4795 18C35.4795 8.36049 27.6391 0.52002 17.9995 0.52002ZM27.6569 18.5374L21.5769 24.6174C21.4284 24.7658 21.2355 24.84 21.0395 24.84C20.8436 24.84 20.6506 24.7658 20.5022 24.6174C20.2053 24.3205 20.2053 23.8396 20.5022 23.5427L25.2848 18.76H8.87953C8.46094 18.76 8.11953 18.4186 8.11953 18C8.11953 17.5814 8.46094 17.24 8.87953 17.24H25.2848L20.5022 12.4574C20.2053 12.1605 20.2053 11.6796 20.5022 11.3827C20.7991 11.0858 21.28 11.0858 21.5769 11.3827L27.6569 17.4627C27.7281 17.531 27.7816 17.6171 27.8202 17.7091C27.8973 17.8961 27.8973 18.1039 27.8202 18.291C27.7816 18.383 27.7281 18.4661 27.6569 18.5374Z'
                    fill='currentColor'
                  />
                </svg>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseCard
